
    var isBrowser = typeof window !== 'undefined' && window.document
    var el;

    function insert() {
      var style = require("!!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].use[3]!./index.scss");
      if (style.__esModule) style = style.default
      var css = typeof style === 'string' ? style : style[0][1]
      var create = false
      if (!el) {
        el = document.createElement('style')
        el.setAttribute('type', 'text/css')
        create = true
      }

      if ('textContent' in el) {
        el.textContent = css
      } else {
        el.styleSheet.cssText = css
      }

      if (create) document.head.appendChild(el)
    }

    if (isBrowser) insert()

    if (module.hot && isBrowser) {
      module.hot.accept("!!../../../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].use[1]!../../../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].use[2]!../../../../node_modules/sass-loader/dist/cjs.js??ruleSet[1].rules[1].use[3]!./index.scss", function() {
        insert()
      });
      module.hot.dispose(function() {
        if (el) el.parentNode.removeChild(el)
      });
    }
  